/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TargetPeriodPerformanceResult {
  targetEnergyCarbonTpThroughput?: number;
  byEnergyCarbonTpThroughput?: number;
  tpPerformance: number;
  tpPerformancePercent: number;
  tpOutcome: 'TARGET_MET' | 'BUY_OUT_REQUIRED' | 'SURPLUS_USED_BUY_OUT_REQUIRED' | 'SURPLUS_USED';
}
